<template>
  <div>
    <q-form ref="editForm">
      <c-card title="공정상세" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="공정" icon="add" @btnClicked="addParent" />
            <c-btn
              v-if="editable"
              :disabled="!saveable"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="data"
              :mappingType="saveType"
              label="저장"
              icon="save"
              @beforeAction="saveData"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <c-text
              :editable="editable"
              :required="true"
              label="공정명"
              name="processName"
              v-model="data.processName">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <c-text
              :editable="editable"
              :required="true"
              label="공정코드"
              name="processCd"
              v-model="data.processCd">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <c-plant
              :required="true"
              :editable="editable"
              label="사업장"
              name="plantCd"
              v-model="data.plantCd">
            </c-plant>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-dept label="관리부서" name="managementDepts" v-model="data.managementDepts" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-text
              :editable="editable"
              :required="true"
              label="정렬순서"
              name="orderNo"
              v-model="data.orderNo">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-radio
              :editable="editable"
              :comboItems="psmYnItems"
              label="PSM대상"
              name="psmFlag"
              v-model="data.psmFlag">
            </c-radio>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-radio
              :editable="editable"
              :comboItems="useFlagItems"
              label="사용여부"
              name="useFlag"
              v-model="data.useFlag">
            </c-radio>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <c-upload
              maxheight="max-height:230px;min-height:230px;"
              :editable="editable"
              label="공정 사진">
            </c-upload>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <c-textarea
              :counter="true"
              :editable="editable"
              label="공정설명"
              name="processDesc"
              :rows="2"
              v-model="data.processDesc">
            </c-textarea>
          </div>
        </template>
      </c-card>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'process-manage',
  props: {
    parentProcessCd: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      psmYnItems: [
        { code: 'Y', codeName: '대상' },
        { code: 'N', codeName: '해당없음' },
      ],
      data: {
        plantCd: null,
        processCd: '',
        processName: '',
        upProcessCd: '',
        processLevelCd: '',
        processDesc: '',
        managementDepts: '',
        orderNo: '',
        useFlag: 'Y',
        psmFlag: 'Y',
      },
      listUrl: '',
      detailUrl: '',
      checkUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveable: false,
      deleteable: false,
      editable: true,
      updateMode: false,
      isSave: false,
      saveUrl: transactionConfig.mdm.process.insert.url,
      saveType: 'POST',
      searchUrl: '',
      selectedProcessCd: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  watch: {
    parentProcessCd() {
      if (this.parentProcessCd) {
        this.rowClick({ processCd: this.parentProcessCd })
      }
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.detailUrl = selectConfig.mdm.process.get.url;
      this.checkUrl = selectConfig.mdm.process.check.url;
      this.insertUrl = transactionConfig.mdm.process.insert.url;
      this.updateUrl = transactionConfig.mdm.process.update.url;
      this.deleteUrl = transactionConfig.mdm.process.delete.url;
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.rowNotSelected = false;
      this.updateMode = true;
      this.selectedProcessCd = row.processCd;
      if (row.processLevelCd == '10') {
        this.lvl1Check = true;
      } else {
        this.lvl1Check = false;
      }
      this.$http.url = this.$format(this.detailUrl, this.selectedProcessCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      },
      () => {
      });
    },
    addParent() {
      this.saveable = true;
      this.deleteable = false;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.data = {
        plantCd: null,
        processCd: '',
        processName: '',
        upProcessCd: '',
        processLevelCd: '10',
        processDesc: '',
        managementDepts: '',
        orderNo: '',
        useFlag: 'Y',
        psmFlag: 'Y',
      };
      this.$emit('isNew');
    },
    reset() {
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.data = {
        plantCd: null,
        processCd: '',
        processName: '',
        upProcessCd: '',
        processLevelCd: '',
        processDesc: '',
        managementDepts: '',
        orderNo: '',
        useFlag: 'Y',
        psmFlag: 'Y',
      };
      this.$emit('isNew');
    },
    saveData() {
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          this.$http.url = this.$format(this.checkUrl, this.data.processCd);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            if (this.updateMode || _result.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?',
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.data.regUserId = this.$store.getters.user.userId
                  this.data.chgUserId = this.$store.getters.user.userId
                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: '안내', // 안내
                message:
                '동일한 시퀀스 아이디가 존재합니다.',
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.processCd);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            console.log(_result)
            this.isSave = !this.isSave;
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('searchDetail');
      if (this.saveType == 'POST') {
        this.reset();
        this.selectedProcessCd = '';
      }
    },
    deleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('searchDetail');
      this.reset();
      this.selectedProcessCd = '';
    },
  }
};
</script>
